<template>
  <a-config-provider :locale="locale[lang.lang]">
    <div id="app">
      <userInfoBox v-show="showPerson"></userInfoBox>
      <editPwd v-if="$store.state.editPwdvisible"></editPwd>
      <editName v-if="$store.state.editNamevisible"></editName>
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import kkKZ from '@/data/i18n/antd-lang-kazakh/kk_KZ'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import viVN from 'ant-design-vue/lib/locale-provider/vi_VN'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapState } from 'vuex'

import editName from './components/editName.vue'
import editPwd from './components/editPwd.vue'
import userInfoBox from './components/userInfo.vue'
import { fetchMaterialColors } from './util/commonFun'
import { QWebChannel } from './util/qwebchannel.js'

export default {
  name: 'App',
  components: {
    editPwd,
    editName,
    userInfoBox,
  },
  data() {
    return {
      locale: {
        zh: zhCN,
        en: enUS,
        vi: viVN,
        kz: kkKZ,
      },
    }
  },
  computed: {
    ...mapState(['userInfo', 'hasLblAuth', 'showPerson', 'lang']),
  },
  mounted() {
    if (typeof qt !== 'undefined') {
      let self = this
      new QWebChannel(qt.webChannelTransport, (channel) => {
        // 获取Qt注册的对象,设置到主窗口（这里的ggLayoutChannel就是Qt端注册时的字符串id）
        window.webToQt = channel.objects['ggLayoutChannel']
        self.$htm_trackEvent('柜柜打开嵌套的云排版', 'fromGuiQt')
      })
    }
    // if(this.$route.path == '/'){
    //   let urlObj = commonFun.getUrlVars()
    //   if(urlObj.thinkerx_material){
    //     sessionStorage.setItem('thinkerx_material', urlObj.thinkerx_material)
    //     if(urlObj.guigui_token){
    //       sessionStorage.setItem('token', urlObj.guigui_token)
    //       this.$router.push({
    //         path:'/materialList',
    //         query:{
    //           thinkerx_material:urlObj.thinkerx_material
    //         }
    //       })
    //     }else{
    //       this.$router.push({
    //         path:'/login',
    //       })
    //     }
    //   }
    // }
    if (this.$route.name != 'engraving') {
      window.localStorage.removeItem('engravingNav')
    }
    // if(!this.$route.meta.noToken){
    //   this.$token('/get_user_info', '', (res) => {
    //     if(res.status == 1){
    //       this.$store.commit('setUserInfo', res.data)
    //       localStorage.setItem('testNowTagId',res.data.custom_tag_id)
    //     }else{
    //       if(!this.$route.meta.noToken){
    //         let pageTo = encodeURIComponent(this.$route.fullPath)
    //         // this.$message({
    //         //   type: "info",
    //         //   message: "身份信息已过期, 请从柜柜重新打开此页面!",
    //         //   duration: 1500,
    //         //   offset: 70,
    //         // });
    //         // window.sessionStorage.removeItem('token')
    //         // window.sessionStorage.removeItem('materialData')
    //         // window.sessionStorage.removeItem('paibanData')
    //         // this.$router.push({
    //         //   path:`/login?pageTo=${pageTo}`
    //         // })
    //       }

    //     }
    //   })
    // }
    // 刷新之后跳回开料清单
    if (
      window.sessionStorage.getItem('isDrawPage') &&
      !window.sessionStorage.getItem('lblJson')
    ) {
      if (window.sessionStorage.getItem('isDrawPage') == 1) {
        window.sessionStorage.setItem('isDrawPage', 0)
        this.$router.push('/materialList')
      }
    }
    let that = this
    window.onbeforeunload = function (e) {
      // 当进行过排版时, 则弹出提示
      if (that.$store.state.paibanData.length > 0) {
        if (window.sessionStorage.getItem('lblJson')) return
        // 如果是在排版相关的页面, 则重新跳回开料清单
        if (that.$route.meta.nav && that.$route.meta.nav == 'draw') {
          window.sessionStorage.setItem('isDrawPage', 1)
        } else {
          window.sessionStorage.setItem('isDrawPage', 0)
        }
        e = e || window.event
        if (e) {
          e.returnValue = '关闭提示'
        }
        return '关闭提示'
      } else {
        window.onbeforeunload = null
      }
    }
  },
  created() {
    // 记录所有的vuex的初始值
    this.$store.commit('recordAllState')
    document.title = '云排版'
    if (this.$route.query) {
      if (this.$route.query.from == 'tiangong') {
        document.title = '天工'
      }
    }
    if (
      !this.$store.state.materialColorList ||
      !this.$store.state.materialColorList.length
    ) {
      fetchMaterialColors()
    }
  },
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: PingFang SC;
}
// @font-face {
//   font-family: 'iconfont';  /* Project id 2721570 */
//   src: url('//at.alicdn.com/t/font_2721570_5lfysiyv55s.woff2?t=1628847978421') format('woff2'),
//        url('//at.alicdn.com/t/font_2721570_5lfysiyv55s.woff?t=1628847978421') format('woff'),
//        url('//at.alicdn.com/t/font_2721570_5lfysiyv55s.ttf?t=1628847978421') format('truetype');
// }
#app {
  height: 100%;
}
.el-message {
  z-index: 99999999 !important;
}
.custom-tool-class {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: transparent;
      box-shadow: none;
    }
  }
}
.feedback-modal {
  .ant-modal {
    background-color: rgba(235, 239, 244, 1);
    width: 780px !important;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 0;
    border-radius: 8px;
    .ant-modal-close-x {
      z-index: 9999;
      position: absolute;
      right: 0;
    }
    .ant-modal-content {
      position: relative;
      border-radius: 8px;
      .ant-modal-body {
        padding: 0;
        padding-top: 60px;
        height: calc(750px - 60px);
        overflow-y: scroll;
        overflow: hidden;
        overscroll-behavior: none;
        .ant-form-item-children :nth-of-type(3) {
          display: flex !important;
        }
      }
      .ant-modal-header {
        padding: 16px 24px 0;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .ant-tabs-bar {
        margin: 0 !important;
        border: 0 !important;
      }
    }
  }
}

.m-line-select-modal {
  .ant-modal {
    width: unset !important;
    min-width: 520px;
  }
  .ant-modal-content {
    .ant-modal-body {
      transition: all 0.3s;
    }
  }
}
</style>
