import { request } from '@/util/vueRequest.js'

import { httpRequest } from '../request'

enum Apis {
  getLabelInfoAPI = '/backend/label/info?from=user',
  specialRemarks = '/special/remarks',
  getLabelList = 'get_electronic_saw_label',
  getFileStartNum = 'file_start_num',
}

export function getLabelInfo() {
  return request({
    url: Apis.getLabelInfoAPI,
    method: 'GET',
  })
}

/**
 * @description 获取柜柜料单特殊工艺筛选信息
 * @link https://api.thinkerx.com/web/#/162/10903
 */
export function getSpecialRemarks() {
  return request({
    url: Apis.specialRemarks,
    method: 'GET',
  })
}

export function getElectronicSawLabel(payload: string) {
  return httpRequest.get(Apis.getLabelList, {
    label_class: payload,
  })
}

export function getFileStartNum(payload: {
  plateTypeNum: number
  fileStartNum: string
}) {
  return httpRequest.get(Apis.getFileStartNum, payload)
}
