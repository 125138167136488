/** 板件吸附范围 */
export const ADSORPTION_RANGE = 40

/** 来源 */
export enum From {
  BLUEN = 'bluen',
  LBL = 'erp',
}

/** 非云排板使用待排版库，如不存在显示的板件图片使用此默认图片 */
export const DEFAULT_SHOW_AWAIT_STORE_IMAGE =
  'https://eggrj.oss-cn-hangzhou.aliyuncs.com/cloud_paiban/await_store/await_store-temp.png'

/** 待排版库图片可存在的图片尺寸 */
export enum AwaitStoreImageSize {
  width = 128,
  height = 134,
}
