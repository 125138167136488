export const ENGRAVING = 'engraving_folder'
export const ELECTRONIC_SAW = 'electronic_saw_folder'
export const LABELING = 'labeling_folder'
export const SIDE_HOLE = 'side_hole_folder'
export const DOCK5F_DRILL = 'dock5F_drill_folder'
export const DOCK6F_DRILL = 'dock6F_drill_folder'
export const PTP = 'PTP_folder'
export const LABEL_IMAGE = 'label_image_folder'
export const PAIBAN1 = 'paiban1'
export const PAIBAN2 = 'paiban2'
export const AIO_FOLDER = 'aio_folder'

export const fileNameSetObj = {
  [ENGRAVING as string]: '雕刻机',
  [ELECTRONIC_SAW as string]: '电子锯',
  [LABELING as string]: '贴标机',
  [SIDE_HOLE as string]: '侧孔机',
  [DOCK5F_DRILL as string]: '五面钻',
  [DOCK6F_DRILL as string]: '六面钻',
  [PTP as string]: 'PTP',
  [LABEL_IMAGE as string]: '贴标图片',
  [PAIBAN1 as string]: '排版图1',
  [PAIBAN2 as string]: '排版图2',
  [AIO_FOLDER as string]: '一体机',
}

export const folderKeyList = [
  ENGRAVING,
  ELECTRONIC_SAW,
  LABELING,
  SIDE_HOLE,
  DOCK5F_DRILL,
  DOCK6F_DRILL,
  PTP,
  LABEL_IMAGE,
]
// 操作记录对应table的类型
export const operateRecordTableType = {
  SPECIAL_MATERIAL_TABLE: 'special_material_table', // 原片管理-特别材质
  STANDARD_MATERIAL_TABLE: 'standard_material_table', // 原片管理-标准板材
  HOLE_SLOT_TABLE: 'hole_slot_table', // 设备对接-开料机设置-孔槽
  KNIFE_STORE_TABLE: 'knife_store_table', // 设备对接-雕刻机-开料对接-刀库
  MENTAOJI_SAW_TABLE: 'mentaoji_saw_table', // 设备对接-雕刻机-开料对接-门套机
  KNIFE_TOOL_TABLE: 'knife_tool_table', // 设备对接-雕刻机-排钻设置-刀具
  CUTTING_PARAMS_PLANK_TABLE: 'cutting_params_plank_table', // 设置对接-雕刻机-切割参数-不同板厚
  LAYOUT_SETTINGS_TABLE: 'layout_settings_table', // 设备对接-雕刻机-贴标设置-排版图设置
  CUSTOMER_FORM_TABLE: 'customer_form_table', // 设备对接-默认生产线-电子锯-自定义表单
  SIDE_HOLE_KNIFE_STORE_TABLE: 'side_hole_knife_store_table', // 设备对接-侧孔机-刀库
  SIDE_HOLE_FRONT_KNIFE_STORE_TABLE: 'side_hole_front_knife_store_table', // 设备对接-侧孔机-正面加工-刀库
  PTP_KNIFE_STORE_TABLE: 'ptp_knife_store_table', // 设备对接-PTP-刀库
  SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V1: 'side_hole_old_knife_store_table_v1', // 设备对接-侧孔机-旧版设置-刀库V1
  SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V3: 'side_hole_old_knife_store_table_v3', // 设备对接-侧孔机-旧版设置-刀库V3
  SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V4: 'side_hole_old_knife_store_table_v4', // 设备对接-侧孔机-旧版设置-刀库V4
  SIDE_HOLE_DRILL_SETTING_DRILL_COMBINATION_FROM_HOLE_TABLE:
    'side_hole_drill_setting_drill_combination_from_hole_table', // 设备对接-排钻设置-排钻组合下刀-从孔列表
  MODEL_AIO_TABLE: 'model_aio_table', // 设备对接-一体机-刀具
}
// 前端自定义唯一id的key值
export const webId = 'webId'
