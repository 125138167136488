import store from '@/store'

/** 将NC数据处理成预排版设置数据 */
export function dealPreLayoutSetting(data: any) {
  const standardPlank = store.state.selectStandardPlank

  const { integrationDeviceSetting } = data
  const { aio_fixture_setting, enable_integration_device } =
    integrationDeviceSetting
  return {
    isPreLayout: true,
    knife: {
      desc: '',
      name: '',
      diameter: Number(data.knife.diameter),
      cutable: true,
      moveZF: '',
      cutZF: '',
      moveF: 0,
      cutF: 0,
      startCode: '',
      stopCode: '',
      preStartCode: '',
      holeable: true,
      slotable: true,
      millableHole: true,
      millableSlot: true,
    },
    cutZ: 0,
    drawPlankHeight: data.countertopSetting.xyReverse
      ? standardPlank.plankWidth
      : standardPlank.plankHeight ?? 2440,
    drawPlankWidth: data.countertopSetting.xyReverse
      ? standardPlank.plankHeight
      : standardPlank.plankWidth ?? 1220,
    panelSize: {
      plankHeight: standardPlank.plankHeight ?? 2440,
      plankWidth: standardPlank.plankWidth ?? 1220,
      plankEdgeOff: standardPlank.plankEdgeOff ?? 0,
      layoutGap: data.boardGapsSetting.boardGap,
      surplusMargin: true,
      surplusTrim: true,
    },
    holeConcentrated: data.holeFaceSetting.holeConcentrated,
    longPlankCuttingSetting: data.longPlankCuttingSetting,
    startPosition: data.countertopSetting.startPosition,
    xyReverse: data.countertopSetting.xyReverse,
    surplusPosition: data.surplusSetting.surplusPosition,
    highgloss_direction: data.highgloss_setting.highgloss_direction,
    uniqueBarCode: data.barCodeSetting.uniqueBarCode,
    apertureSlotConcentrated: data.holeFaceSetting.apertureSlotConcentrated,
    cuttingEquipment: 'engraving',
    decimal: data.decimalSetting.decimal,
    sideBoardPlacing: data.edgePlankSlotDirection.sideBoardPlacing,
    processChainAndHoleOnBigPlaneBack:
      data.holeFaceSetting.processChainAndHoleOnBigPlaneBack,
    prorate_holes_slots_of_both_side:
      data.holeFaceSetting.prorate_holes_slots_of_both_side,
    throughHoleSlotSameSide: data.holeFaceSetting.throughHoleSlotSameSide,
    percentage_of_back_hole_slot:
      data.holeFaceSetting.percentage_of_back_hole_slot,
    plate_knife_map: null,
    genSimpleLabelBarcode: data.barCodeSetting.genSimpleLabelBarcode,
    through_slot_two_side_cut:
      data.cuttingPunchingSetting?.through_slot_two_side_cut,
    throughTowSideToCut: data.cuttingPunchingSetting?.throughTowSideToCut,
    aio_fixture_setting,
    enable_integration_device,
    sawSetting: data?.sawSetting,
  }
}

/** 将预排版配置数据转换为nc配置数据 */
export function dealPreLayoutSettingFromNCSetting(data: any) {
  const { aio_fixture_setting, enable_integration_device } = data
  const {
    aio_avoid_fixture,
    aio_first_fixture_height,
    aio_fixture_direction,
    aio_fixture_length,
    aio_fixture_width,
    aio_second_fixture_height,
  } = aio_fixture_setting
  // 锯片横切刀直径数组
  const hSawKnives = data.sawSetting?.mentaoji_saws
    ?.filter((item: any) => item.saw_direction == 'H')
    ?.map((item: any) => item.saw_width)
  return {
    knife: {
      diameter: Number(data.knife.diameter),
    },
    holeFaceSetting: {
      holeConcentrated: data.holeConcentrated,
      prorate_holes_slots_of_both_side: data.prorate_holes_slots_of_both_side,
      apertureSlotConcentrated: data.apertureSlotConcentrated,
      processChainAndHoleOnBigPlaneBack: data.processChainAndHoleOnBigPlaneBack,
      throughHoleSlotSameSide: data.throughHoleSlotSameSide,
      percentage_of_back_hole_slot: data.percentage_of_back_hole_slot,
    },
    highgloss_setting: { highgloss_direction: data.highgloss_direction },
    edgePlankSlotDirection: { sideBoardPlacing: data.sideBoardPlacing },
    countertopSetting: {
      startPosition: data.startPosition,
      xyReverse: data.xyReverse,
    },
    // as { [key: string]: any },
    longPlankCuttingSetting: data.longPlankCuttingSetting,
    surplusSetting: {
      surplusPosition: data.surplusPosition,
    },
    decimalSetting: { decimal: data.decimal },
    boardGapsSetting: {
      boardGap: data.panelSize.layoutGap,
    },
    barCodeSetting: {
      uniqueBarCode: data.uniqueBarCode,
      genSimpleLabelBarcode: data.genSimpleLabelBarcode,
    },
    cuttingPunchingSetting: {
      through_slot_two_side_cut: data.through_slot_two_side_cut,
      throughTowSideToCut: data.throughTowSideToCut,
    },
    integrationDeviceSetting: {
      aio_fixture_setting: {
        aio_avoid_fixture,
        aio_fixture_length,
        aio_fixture_width,
        aio_first_fixture_height,
        aio_second_fixture_height,
        aio_fixture_direction,
      },
      enable_integration_device,
    },
    sawSetting: {
      mentaoji_enable: data.sawSetting.mentaoji_enable,
      sawToLayout: data.sawSetting.sawToLayout,
      horizontalWidth: data.sawSetting?.mentaoji_saws
        ? Math.min(...hSawKnives)
        : data.sawSetting.horizontalWidth,
      edgeNoSaw: data.sawSetting.edgeNoSaw,
      edgeNoSawSide: data.sawSetting.edgeNoSawSide,
    },
  }
}

export function checkPrelayoutSettingChanged(obj: any, obj2: any): boolean {
  let keys = Object.keys(obj).filter(
    (item: any) => typeof obj[item] !== 'object'
  )
  // 孔槽集中面设置的按比例分配设置比较
  if (keys.includes('holeConcentrated')) {
    if (obj.prorate_holes_slots_of_both_side) {
      keys = keys.filter(
        (item) =>
          !['holeConcentrated', 'apertureSlotConcentrated'].includes(item)
      )
    } else {
      keys = keys.filter((item) => item !== 'percentage_of_back_hole_slot')
    }
  }
  if (
    keys.some((key: any) => {
      return obj[key] != obj2[key]
    })
  ) {
    return true
  } else {
    return Object.keys(obj)
      .filter((item: any) => {
        // 长板切割开关控制对象比较
        if (
          item == 'longPlankCuttingSetting' &&
          !obj[item].longPlankCuttingEnable &&
          obj[item].longPlankCuttingEnable == obj2[item].longPlankCuttingEnable
        ) {
          return false
          // xy轴互换控制相关对象比较
        } else if (
          item == 'countertopSetting' &&
          !obj[item].xyReverse &&
          obj[item].xyReverse == obj2[item].xyReverse
        ) {
          return false
        } else if (item === 'custom_special_hole_slot_side') {
          return false
        } else {
          return typeof obj[item] === 'object'
        }
      })
      .map((key) => {
        if (Array.isArray(obj[key])) {
          return (
            obj[key].length != obj2[key].length ||
            obj[key].some((item: any) => !obj2[key].includes(item))
          )
        } else {
          return checkPrelayoutSettingChanged(obj[key], obj2[key])
        }
      })
      .some((item) => item)
  }
}

/**
 * @description 对比两个数据之间的差异 并提取差异
 * @param prevData 之前数据
 * @param currentData 当前数据
 * @returns
 */
export function deepCompare(
  prevData: any,
  currentData: any,
  isFleet?: boolean
) {
  const fleet: { [key: string]: string[] } = {}
  function compareObjects(
    obj1: any,
    obj2: any,
    visitedPrev = new Set(),
    visitedCurrent = new Set()
  ) {
    const diff: any = {}

    // 防止循环引用
    if (visitedPrev.has(obj1) || visitedCurrent.has(obj2)) {
      return diff
    }
    visitedPrev.add(obj1)
    visitedCurrent.add(obj2)

    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])
    for (const key of allKeys) {
      if (
        typeof obj1[key] === 'object' &&
        obj1[key] !== null &&
        typeof obj2[key] === 'object' &&
        obj2[key] !== null
      ) {
        const nestedDiff = compareObjects(
          obj1[key],
          obj2[key],
          visitedPrev,
          visitedCurrent
        )
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff
        }
      } else if (obj1[key] !== obj2[key]) {
        if (!diff[key]) {
          diff[key] = {}
        }
        diff[key] = { prev: obj1[key], current: obj2[key] }
        fleet[key] = [obj1[key], obj2[key]]
      }
    }

    return isFleet ? fleet : diff
  }

  return compareObjects(prevData, currentData)
}
