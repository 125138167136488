// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// @ts-nocheck
import { messages } from '@/data/i18n'
import { translateLang } from '@/data/i18n/translateLang'
import ClipperLib from '@/util/clipper_unminified.js'
import { Message, modalMsg } from '@/util/commonFuncs'
import { formatI18n } from '@/util/i18n'
import '@/util/vueRequest.js'
// @yige/base 位置不要动
import '@yige/base/bin/index.css'
import Antd from 'ant-design-vue'
import ConfigProvider from 'ant-design-vue'
import { Modal, message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import axios from 'axios'
import clipboard from 'clipboard'
import ElementUI from 'element-ui'
import fabric from 'fabric'
import VConsole from 'vconsole'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueAxios from 'vue-axios'

import App from './App'
import './common/css/common.css'
import './common/css/iconfont.css'
import GTView from './components/translateComp/g-t-view.vue'
import router from './router'
import store from './store'
import './style/element-variables.scss'
import './style/replace.css'

window.ClipperLib = ClipperLib
window.$copy = (data) => JSON.parse(JSON.stringify(data))
Vue.use(VueAwesomeSwiper)
const isProductionEnv = process.env.VUE_APP_ENV === 'production'
const win = window as any

Vue.use(ConfigProvider)
Vue.prototype.$antdMessage = message
Vue.prototype.$modalMsg = modalMsg
Vue.prototype.$antdConfirm = Modal.confirm

Vue.prototype.$log = function (obj: PlainObject, line: number) {
  console.log(JSON.parse(JSON.stringify(obj)))
  console.log(
    `%c 这是分割线----------当前打印的是${line}行数据`,
    'color:#f00;font-size: 18px;'
  )
}

Vue.prototype.$translateLang = translateLang
function createVConsole() {
  if (win.webToQt && !isProductionEnv) {
    Vue.use(new VConsole())
  }
}
function createVue() {
  createVConsole()
  Vue.config.productionTip = false
  Vue.use(VueAxios, axios)
  Vue.prototype.$core = axios
  Vue.prototype.$copy = clipboard
  Vue.use(Antd)
  Vue.use(ElementUI)
  // @ts-ignore
  Vue.use(fabric)
  Vue.component('t-text', GTView)

  Vue.prototype.$htm_trackEvent = function (
    type: string,
    event: any,
    value = ''
  ) {
    _hmt.push(['_trackEvent', type, event, value])
  }
  // 全局修改$message的显示时间和显示高度
  Vue.prototype.$message = Message

  // 假设用户自定义数据放到 VueX 里面
  const translateStore = store.state.customerTranslateData ?? {}

  // 传入对象
  // const i18n = formatI18n('zh', messages, translateStore.customerTranslate)

  // 传入函数
  const i18n = formatI18n(
    'zh',
    messages,
    () => store.state.customerTranslateData ?? {}
  )
  window['i18n'] = i18n

  const messageTypeInfo = ['info', 'success', 'error', 'warning']
  messageTypeInfo.forEach((v) => {
    Vue.prototype.$message[v] = function (msg: any) {
      if (typeof msg == 'string') {
        ;(ElementUI as any).Message[v]({
          duration: 1500,
          offset: 60,
          message: msg,
        })
      } else {
        ;(ElementUI.Message as any)[v]({ duration: 1500, offset: 60, ...msg })
      }
    }
  })

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    i18n,
    template: '<App/>',
    beforeCreate() {
      Vue.prototype.$bus = this
    },
  })
}

// 刷新时重置导入订单的状态
window.addEventListener('load', () => {
  sessionStorage.setItem('isImportOrder', false)
})

createVue()
