export type ValType = { key1: string; key2: string }

const buryPointPayloadMap = new Map<string, ValType>([
  [
    '雕刻机-切割参数-高光设置-排版时小板高光面排在大板-front',
    { key1: 'engraving', key2: 'high_gloss_front' },
  ],
  [
    '雕刻机-切割参数-高光设置-排版时小板高光面排在大板-back',
    { key1: 'engraving', key2: 'high_gloss_back' },
  ],
  [
    '雕刻机-切割参数-共边切割-共边切割',
    { key1: 'engraving', key2: 'coedge_cutting' },
  ],
  [
    '雕刻机-切割参数-共边加速-共边加速',
    { key1: 'engraving', key2: 'coedge_accelerate' },
  ],
  [
    '雕刻机-切割参数-切割打孔方式-圆弧插补指令前缀',
    { key1: 'engraving', key2: 'arcInstruction' },
  ],
  [
    '雕刻机-切割参数-切割打孔方式-铣槽时未拉穿的槽自动清理圆角-true',
    { key1: 'engraving', key2: 'auto_clear_fillet_slot' },
  ],
  [
    '雕刻机-切割参数-切割打孔方式-铣槽走刀方式-out_to_inner',
    { key1: 'engraving', key2: 'mill_slots_out_to_inner' },
  ],
  [
    '雕刻机-切割参数-切割打孔方式-铣槽走刀方式-inner_to_out',
    { key1: 'engraving', key2: 'mill_slots_inner_to_out' },
  ],
  [
    '雕刻机-切割参数-加工速度优化-切割转角减速-true',
    { key1: 'engraving', key2: 'angle_deceleration_enabled' },
  ],
  [
    '雕刻机-切割参数-加工速度优化-切割转角减速-转角前后距离',
    { key1: 'engraving', key2: 'angle_deceleration_list' },
  ],
  // TODO
  [
    '雕刻机-切割参数-加工速度优化-切割时斜下刀-false',
    { key1: 'engraving', key2: 'oblique' },
  ],
  [
    '雕刻机-切割参数-加工速度优化-反面修边斜下刀-true',
    { key1: 'engraving', key2: 'trim_oblique' },
  ],
  [
    '雕刻机-切割参数-长板加工-启用',
    { key1: 'engraving', key2: 'long_plank_cutting_enable' },
  ],
  [
    '雕刻机-切割参数-长板加工-真空关闭方式-单块长板切割后',
    { key1: 'engraving', key2: 'after_one_plank' },
  ],
  [
    '雕刻机-切割参数-长板加工-真空关闭方式-整张大板切割后',
    { key1: 'engraving', key2: 'after_all_plank' },
  ],
  [
    '雕刻机-切割参数-废料自动截断-废料自动截断-true',
    { key1: 'engraving', key2: 'waste_cutting' },
  ],
  [
    '雕刻机-切割参数-废料自动截断-截断尺寸大于',
    { key1: 'engraving', key2: 'waste_size' },
  ],
  [
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边-true',
    { key1: 'engraving', key2: 'is_first_trim_single_panel' },
  ],
  [
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边方向-左边、上边',
    { key1: 'engraving', key2: 'topLeft' },
  ],
  [
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边方向-左边、下边',
    { key1: 'engraving', key2: 'bottomLeft' },
  ],
  [
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边方向-右边、上边',
    { key1: 'engraving', key2: 'topRight' },
  ],
  [
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边方向-右边、下边',
    { key1: 'engraving', key2: 'bottomRight' },
  ],
  [
    '雕刻机-切割参数-锯切设置-启用锯切',
    { key1: 'engraving', key2: 'mentaojiSizeSetting' },
  ],
  [
    '雕刻机-切割参数-锯切设置-不生成生成切割路径',
    { key1: 'engraving', key2: 'noVerticalSaw' },
  ],
  [
    '雕刻机-切割参数-分层切割-铣槽拉槽分层切割',
    { key1: 'engraving', key2: 'layered_slot_cutting' },
  ],
  [
    '雕刻机-排钻设置-启用排钻包-启用排钻包-true',
    { key1: 'engraving', key2: 'enabled' },
  ],
  [
    '雕刻机-排钻设置-排钻组合下刀-排钻组合下刀-true',
    { key1: 'engraving', key2: 'gang_drill_combination_enable' },
  ],
  [
    '雕刻机-排钻设置-加工设置-排钻加工模式-recent_hole',
    { key1: 'engraving', key2: 'recent_hole' },
  ],
  [
    '雕刻机-排钻设置-加工设置-排钻加工模式-group_hole',
    { key1: 'engraving', key2: 'drill_process_mode' },
  ],
  [
    '雕刻机-一体机设置-输出一体机加工文件-输出一体机加工文件-true',
    { key1: 'engraving', key2: 'enable_integration_device' },
  ],
  // TODO
  [
    '雕刻机-一体机设置-文件格式-文件格式-xinhongtian',
    { key1: 'engraving', key2: 'xinhongtian' },
  ],
  [
    '雕刻机-一体机设置-文件格式-文件格式-pinmai',
    { key1: 'engraving', key2: 'pinmai' },
  ],
  [
    '雕刻机-一体机设置-文件格式-文件格式-ruinuo',
    { key1: 'engraving', key2: 'ruinuo' },
  ],
  [
    '雕刻机-一体机设置-文件格式-文件格式-kaihong',
    { key1: 'engraving', key2: 'kaihong' },
  ],
  [
    '雕刻机-一体机设置-文件格式-文件格式-jidong',
    { key1: 'engraving', key2: 'jidong' },
  ],
  [
    '雕刻机-一拖二雕刻机-输出一拖二雕刻机加工文件-输出一拖二雕刻机加工文件',
    { key1: 'engraving', key2: 'enable_one_for_two_device' },
  ],
  [
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点-true',
    { key1: 'engraving', key2: 'enable_one_for_two_start_position' },
  ],
  [
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点-左上角',
    { key1: 'engraving', key2: 'left_top' },
  ],
  [
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点-右上角',
    { key1: 'engraving', key2: 'right_top' },
  ],
  [
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点-左下角',
    { key1: 'engraving', key2: 'left_bottom' },
  ],
  [
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点-右下角',
    { key1: 'engraving', key2: 'right_bottom' },
  ],
  [
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边-true',
    { key1: 'engraving', key2: 'one_for_two_single_panel_trim' },
  ],
  [
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边方向-左边、上边',
    { key1: 'engraving', key2: 'topLeft' },
  ],
  [
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边方向-左边、下边',
    { key1: 'engraving', key2: 'bottomLeft' },
  ],
  [
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边方向-右边、上边',
    { key1: 'engraving', key2: 'topRight' },
  ],
  [
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边方向-右边、下边',
    { key1: 'engraving', key2: 'bottomRight' },
  ],
  [
    '雕刻机-NC导出设置-板件条码设置-板件条码唯一-uniqueBarCode',
    { key1: 'engraving', key2: 'uniqueBarCode' },
  ],
  // 雕刻机贴标设置
  [
    '雕刻机-贴标设置-自动贴标顺序设置-按照开料顺序贴标',
    { key1: 'engraving', key2: 'cutOrder' },
  ],
  [
    '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从下往上贴标',
    { key1: 'engraving', key2: 'toTop' },
  ],
  [
    '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从上往下贴标',
    { key1: 'engraving', key2: 'toBottom' },
  ],
  [
    '雕刻机-贴标设置-贴标自动避让-贴标自动避让-true',
    { key1: 'engraving', key2: 'labelingDodge' },
  ],
  // TODO
  [
    '原片管理-自动选用特殊大板',
    { key1: 'base_material', key2: 'is_auto_selected' },
  ],
  [
    '原片管理-自动添加特殊大板弹窗',
    { key1: 'base_material', key2: 'auto_add_special_plate' },
  ],
  [
    '原片管理-特殊大板只排超尺小板',
    { key1: 'base_material', key2: 'only_layout_too_big' },
  ],
  [
    '原片管理-原片下料刀选择',
    { key1: 'base_material', key2: 'plate_knife_map' },
  ],
  [
    '余料管理-单板件开始排版',
    { key1: 'surplus_storage', key2: 'single_choice_paiban' },
  ],
  [
    '余料管理-多板件开始排版',
    { key1: 'surplus_storage', key2: 'double_choice_paiban' },
  ],
  ['余料管理-不翻版加工', { key1: 'surplus_storage', key2: 'surplus_no_roll' }],
  ['补件仓库-删除', { key1: 'supplement', key2: 'delete' }],
  ['补件仓库-批量删除', { key1: 'supplement', key2: 'batch_delete' }],
  ['补件仓库-排版', { key1: 'supplement', key2: 'single_choice_paiban' }],
  ['补件仓库-批量排版', { key1: 'supplement', key2: 'double_choice_paiban' }],
  // TODO 五六
  [
    '五六面钻-通用参数设置-通孔分双面打通',
    { key1: 'dock56f', key2: 'tow_side_con_hole' },
  ],
  [
    '五六面钻-通用参数设置-矩形板没有孔槽不输出文件',
    { key1: 'dock56f', key2: 'non_holes_not_files' },
  ],
  [
    '五六面钻-通用参数设置-正面文件标识',
    { key1: 'dock56f', key2: 'front_opposite_file_identifier' },
  ],
  [
    '五六面钻-通用参数设置-反面文件标识',
    { key1: 'dock56f', key2: 'front_opposite_file_identifier' },
  ],
  [
    '五六面钻-通用参数设置-圆弧描述方式-线段成型描述',
    { key1: 'dock56f', key2: 'line_points' },
  ],
  [
    '五六面钻-通用参数设置-圆弧描述方式-圆弧指令描述',
    { key1: 'dock56f', key2: 'arc_command' },
  ],
  [
    '五六面钻-通用参数设置-宽槽拆解为多条窄槽加工-true',
    { key1: 'dock56f', key2: 'split_slot' },
  ],
  [
    '五六面钻-通用参数设置-拆解后单条槽宽度',
    { key1: 'dock56f', key2: 'split_slot' },
  ],
  [
    '五六面钻-通用参数设置-相邻的等宽等深槽拉通处理',
    { key1: 'dock56f', key2: 'is_not_merge_slot' },
  ],
  [
    '五六面钻-通用参数设置-板件长边摆放方向选择框-true',
    { key1: 'dock56f', key2: 'drill_layout_dir' },
  ],
  [
    '五六面钻-通用参数设置-板件长边摆放方向-横向',
    { key1: 'dock56f', key2: 'drill_layout_dir_horizontal' },
  ],
  [
    '五六面钻-通用参数设置-板件长边摆放方向-纵向',
    { key1: 'dock56f', key2: 'drill_layout_dir_vertical' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向选择框-true',
    { key1: 'dock56f', key2: 'drill_solt_dir' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向-系统默认',
    { key1: 'dock56f', key2: 'drill_solt_dir_default' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向-前和左',
    { key1: 'dock56f', key2: 'drill_solt_dir_front_left' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向-前和右',
    { key1: 'dock56f', key2: 'drill_solt_dir_front_right' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向-后和左',
    { key1: 'dock56f', key2: 'drill_solt_dir_back_left' },
  ],
  [
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向-后和右',
    { key1: 'dock56f', key2: 'drill_solt_dir_back_right' },
  ],
  [
    '五六面钻-通用参数设置-前封边摆放方向-true',
    { key1: 'dock56f', key2: 'front_edge_fixed' },
  ],
  [
    '五六面钻-通用参数设置-固定在-front',
    { key1: 'dock56f', key2: 'front_edge_fixed_front' },
  ],
  [
    '五六面钻-通用参数设置-固定在-back',
    { key1: 'dock56f', key2: 'front_edge_fixed_back' },
  ],
  [
    '五六面钻-通用参数设置-固定在-left',
    { key1: 'dock56f', key2: 'front_edge_fixed_left' },
  ],
  [
    '五六面钻-通用参数设置-固定在-right',
    { key1: 'dock56f', key2: 'front_edge_fixed_right' },
  ],
  [
    '五六面钻-通用参数设置-坐标值保留位数',
    { key1: 'dock56f', key2: 'drill_decimal_num' },
  ],
  [
    '五六面钻-通用参数设置-板件长边≤',
    { key1: 'dock56f', key2: 'long_edge_rotate_range' },
  ],
  [
    '下载NC顺带导出标签文件',
    { key1: 'more_setting', key2: 'is_follow_export_tag' },
  ],
  ['顺带导出PDF', { key1: 'more_setting', key2: 'is_follow_export_tag_pdf' }],
  ['顺带导出图片', { key1: 'more_setting', key2: 'is_follow_export_tag_pic' }],
])

export { buryPointPayloadMap }
