<template>
  <div class="top-nav">
    <div class="official-website relative">
      <div class="setting title-text" @click.stop="handleShowPop(1)">
        <a-icon type="more" style="color: #fff" /><span class="setting-text">{{
          $t('main.head.setting')
        }}</span>
      </div>
      <div
        class="setting-menu absolute br4 align-center"
        v-show="isShowSettingPop"
      >
        <div
          v-for="item in settingNav"
          :key="item.id"
          class="setting-item mv4 br4"
          :title="translateLang(item.title)"
        >
          <router-link :to="item.path" class="a-text w100 h100 ellipsis">
            {{ translateLang(item.title) }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="official-website relative">
      <span class="title-text" @click.stop="handleShowPop(2)">{{
        $t('main.head.find')
      }}</span>
      <div class="find absolute br4 align-center" v-show="isShowFindPop">
        <div class="find-item mv4 br4" @click="handleOpenNotice">
          <span class="notice relative" id="announcement_btn">
            {{ $t('main.head.notice') }}
            <i
              class="el-icon-chat-dot-round message-icon absolute"
              v-if="showUnreadIcon"
            ></i>
          </span>
        </div>
        <div class="find-item mv4 br4">
          <span
            @click="handleGotoOfficialWebsite"
            id="enter_official_website_btn"
            class="nav-box w100 h100 ellipsis"
            :title="translateLang('main.head.enterOfficial')"
            >{{ $t('main.head.enterOfficial') }}</span
          >
        </div>
        <div class="find-item mv4 br4">
          <span
            @click="handleGotoOfficialEquipEnvironment"
            id="machine_equipment_btn"
            class="nav-box w100 h100 ellipsis"
            :title="translateLang('main.head.equipment')"
            >{{ $t('main.head.equipment') }}</span
          >
        </div>
        <div class="find-item mv4 br4">
          <div id="components-dropdown-demo-placement">
            <a-dropdown placement="bottomCenter" :trigger="['click']">
              <span class="nav-box w100 h100" @click.stop="handleShowPop(3)">{{
                $t('main.head.help')
              }}</span>
              <a-menu slot="overlay" class="help-box">
                <a-menu-item>
                  <a
                    @click="handleShowPop(2)"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.yuque.com/linyaoyu/vr6nhn/beohd8"
                    >{{ $t('main.head.useLesson') }}</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    @click="handleShowPop(2)"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.douyin.com/user/MS4wLjABAAAAQAH6_rqubS54ts0ct0wEFk4Wqxou7V9ThFYHE7KIRcGuHmgfNqsTSN7-J0V3XTyj"
                    >{{ $t('main.head.videoLesson') }}</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <div class="find-item mv4 br4">
          <span
            @click="handleGotoThinkerxHome"
            id="enter_company_website_btn"
            class="nav-box w100 h100 ellipsis"
            :title="translateLang('main.head.enterThinkerOfficial')"
            >{{ $t('main.head.enterThinkerOfficial') }}</span
          >
        </div>
      </div>
    </div>
    <a-popover
      overlayClassName="lang-popover"
      placement="bottomRight"
      trigger="click"
      color="#000"
      v-model="langPopVisibel"
    >
      <template #title>
        <div class="flex flex-dir--top flex-cross--top lang-popover-title w100">
          <a-button
            type="link"
            class="lang-link-btn w100"
            v-for="item in langList"
            :key="item.value"
            :id="`lang_select_${item.value}`"
            @click="handleChangeLang(item.value)"
          >
            {{ item.name }}
          </a-button>
        </div>
      </template>
      <template #content>
        <div
          style="padding: 4px 8px; cursor: pointer"
          @mousedown="(e) => e.preventDefault()"
          @click="handleEditLang"
          id="edit_lang_btn"
          class="color-f"
        >
          <!-- <i class="iconfont icon-edit_02"></i> -->
          {{ $t('common.editTrans') }}
        </div>
      </template>
      <div style="height: 80%" class="flex flex-cross--center">
        <a-button type="link">{{ currentLang.name }}</a-button>
      </div>
    </a-popover>
    <div
      class="user-box"
      v-if="!showTiangong"
      @click.stop="showPersonInfo"
      id="showPersonInfo_btn"
    >
      <div class="user-head">
        <img :src="userInfo.logo_url || defaultLogoUrl" alt="头像" />
        <!-- <img :src="userInfo.logo_url" alt="头像" /> -->
      </div>
      <div class="user-name">
        <span>{{ userInfo.name }}</span>
        <i
          style="fontsize: 12px"
          class="iconfont icon-zhankai"
          v-if="!showPerson"
        ></i>
        <i
          style="fontsize: 12px"
          class="iconfont icon-arrow"
          v-else-if="showPerson"
        ></i>
      </div>
    </div>
    <a-modal
      v-model="editVisibel"
      :title="$t('common.translateManager')"
      width="1200px"
      :footer="null"
      :bodyStyle="{
        padding: '0',
        'padding-right': '25px',
      }"
    >
      <translateManager></translateManager>
    </a-modal>
  </div>
</template>

<script>
import translateManager from '@/components/translateManager/index.vue'
import { EventBusKey } from '@/util/bus'
import { translate } from '@/util/commonFun'
import { mapMutations, mapState } from 'vuex'

import { getDealCustomTranslation } from './translateManager/utils/dealData'

export default {
  props: {
    showTiangong: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onReloadLang'],
  components: {
    // editPwd,
    // editName,
    // userInfoBox
    translateManager,
  },
  data() {
    return {
      //语言列表
      langList: [
        {
          name: '中文(Chinese)',
          value: 'zh',
        },
        {
          name: '英文(English)',
          value: 'en',
        },
        { name: '哈萨克语(Қазақ тілі)', value: 'kz' },
        { name: '越南语(Tiếng Việt)', value: 'vi' },
      ],
      currentLang: {
        name: '中文(Chinese)',
        value: 'zh',
      },
      // 导航栏
      navList: [
        {
          icon: 'assets/icon/detailedlist_20pt.svg',
          icon2: 'assets/icon/detailedlist_20pt_checked.svg',
          title: '排版生产',
          name: 'detailedlist',
          path: '/materialList',
        },
        {
          icon: 'assets/icon/detailedlist_20pt.svg',
          icon2: 'assets/icon/detailedlist_20pt_checked.svg',
          title: '排版历史',
          name: 'historylist',
          path: '/paibanHistory',
        },
        {
          icon: 'assets/icon/equipment_20pt.svg',
          icon2: 'assets/icon/equipment_20pt_checked.svg',
          title: '设备对接',
          name: 'equipment',
          path: '/equipment',
        },
      ],
      activeNav: 'equipment',
      // 显示排版生产额外的导航栏
      showExtraNav: false,
      // 记录额外的导航栏
      extraNav: [
        {
          id: 1,
          title: '开料清单',
          name: 'material',
          path: '/materialList',
        },
        {
          id: 2,
          title: '原片管理',
          name: 'baseMaterial',
          path: '/baseMaterial',
        },
        {
          id: 3,
          title: '余料仓',
          name: 'supplus',
          path: '/supplusStorage',
        },
      ],
      // 记录设置打开的导航栏
      settingNav: [
        {
          id: 1,
          title: 'main.head.history',
          path: '/paibanHistory',
        },
        {
          id: 2,
          title: 'main.head.plankManager',
          path: '/baseMaterial',
        },
        {
          id: 3,
          title: 'main.head.tagManager',
          path: '/printTag?from=temp',
        },
        {
          id: 4,
          title: 'main.head.suplumentManager',
          path: '/bujianManage',
        },
        {
          id: 5,
          title: 'main.head.fileManager',
          path: '/fileManage',
        },
        {
          id: 6,
          title: 'main.head.prolineManager',
          path: '/equipment',
        },
        {
          id: 7,
          title: 'main.head.workeShop',
          path: '/workShopProduction',
        },
        {
          id: 8,
          title: 'preLayoutSetting.title',
          path: '/preLayoutSetting',
        },
      ],
      // 默认头像
      defaultLogoUrl: require('@/assets/logo_url.png'),
      // 语言编辑弹窗
      editVisibel: false,
      langPopVisibel: false,
      // 是否重新加载语言
      isReloadLang: false,
    }
  },
  computed: {
    ...mapState([
      'userInfo',
      'hasLblAuth',
      'showPerson',
      'isShowSettingPop',
      'isShowFindPop',
    ]),
    extraActiveNav() {
      if (this.$route.meta.child) {
        let name = this.$route.meta.child
        return name
      } else {
        return ''
      }
    },
    showUnreadIcon() {
      return this.$store.state.noReadNotice
    },
  },
  watch: {
    $route() {
      this.changeActiveNav()
    },
    isReloadLang(val) {
      this.$emit('onReloadLang', val)
    },
  },
  methods: {
    ...mapMutations(['setCustomerTranslateData']),
    //同步翻译
    translateLang(key) {
      return translate(key)
    },
    // 自定义语言页面
    handleEditLang() {
      this.editVisibel = true
      this.langPopVisibel = false
    },
    //切换语言
    async handleChangeLang(lang) {
      this.isReloadLang = true
      const currentLang = this.langList.find((item) => item.value === lang)
      this.$data.currentLang = currentLang ?? {
        name: '中文(Chinese)',
        value: 'zh',
      }
      if (this.$data.currentLang.value !== 'zh')
        await getDealCustomTranslation(this.$data.currentLang.value)
      else this.setCustomerTranslateData(null)
      this.$i18n.locale = this.$data.currentLang.value
      this.isReloadLang = false
      this.$data.lang = this.$data.currentLang.value
      sessionStorage.setItem('lang', this.$data.currentLang.value)
      this.langPopVisibel = false
      this.$store.commit('lang/setLang', this.$data.currentLang.value)
      // 全局通知语言已切换
      this.$bus.$emit(EventBusKey.TRANSLATE, { type: 'changeLang' })
    },
    // 切换导航栏
    changeNav(item) {
      if (!this.hasLblAuth) {
        this.$message({
          message: '当前子账号只能查看排版!',
          type: 'info',
        })
        return
      }
      if (item.path != '') {
        if (item.path == '/equipment' && this.showTiangong) {
          this.$router.push('/equipment?from=tiangong')
          return
        }
        if (item.path == '/newPaiban') {
          if (this.$store.state.paibanData.length > 0) {
            this.$message({
              message: '暂未进行过排版, 请先进行排版!',
              type: 'info',
            })
          }
          return
        }
        this.$router.push(item.path)
        window.localStorage.setItem('blnavstatus', item.name)
      } else {
        this.$message({
          type: 'info',
          message: '敬请期待',
          duration: 1000,
          offset: 60,
        })
      }
    },
    // 跳转到拍板生产中的选项
    gotoExtraNav(item) {
      if (item.path == '') {
        this.$message({
          type: 'info',
          message: '敬请期待',
          duration: 1000,
          offset: 60,
        })
        return
      }
      this.$router.push(item.path)
    },
    // 打开公告
    handleOpenNotice() {
      this.$store.commit('setShowNotice', true)
    },
    // 前往官网
    handleGotoOfficialWebsite() {
      if (this.showTiangong) {
        window.open('https://www.guigui.com.cn/tiangong_official/tiangong.html')
      } else {
        window.open('http://www.bluen.cn')
      }
    },
    //跳转到机器设备
    handleGotoOfficialEquipEnvironment() {
      const { href } = this.$router.resolve({
        path: `/equipEnvironment`,
      })
      window.open(href, '_blank')
    },
    //跳转到帮助文档
    gotoHelpingDocument() {
      _hmt.push(['_trackEvent', '帮助', 'click'])
      window.open('https://www.yuque.com/linyaoyu/vr6nhn/beohd8')
    },
    handleGotoThinkerxHome() {
      window.open('http://www.thinkerx.com/')
    },
    // 更改顶部导航选中
    changeActiveNav() {
      if (this.$route.meta.nav) {
        let navName = this.$route.meta.nav
        switch (navName) {
          case 'equip':
            this.activeNav = 'equipment'
            break
          case 'material':
            this.activeNav = 'detailedlist'
            break
          case 'draw':
            this.activeNav = 'typesetting'
            break
          case 'history':
            this.activeNav = 'historylist'
            break
          case 'baseMaterial':
            this.activeNav = 'basematerial'
            break
        }
      }
    },
    // 显示个人中心悬浮框
    showPersonInfo() {
      this.$store.commit('setShowPerson', !this.showPerson)
    },
    // hidePerson(){
    //   this.showPerson = false

    // }
    handleShowPop(index) {
      if (index === 1) {
        this.$store.commit('setShowSettingPop', !this.isShowSettingPop)
        this.$store.commit('setShowFindPop', false)
      } else if (index === 2) {
        this.$store.commit('setShowSettingPop', false)
        this.$store.commit('setShowFindPop', !this.isShowFindPop)
      } else {
        this.$store.commit('setShowSettingPop', false)
      }
    },
  },
  mounted() {
    this.changeActiveNav()
    this.$data.lang = sessionStorage.getItem('lang') ?? 'zh'
  },
}
</script>

<style scoped lang="less">
.top-nav {
  display: flex;
  height: 100%;
  padding-right: 27px;
  align-items: center;
  div.official-website {
    z-index: 999;
    cursor: pointer;
    padding-right: 24px;
    span {
      font-size: 14px;
      font-family: 'PingFangSC-Regular', 'PingFang SC';
      font-weight: 400;
    }
    .title-text {
      color: #fff;
    }
    .a-text {
      display: inline-block;
      color: rgba(0, 0, 0, 0.65);
    }
    .setting:hover {
      .setting-text,
      .anticon {
        color: #18a8c7 !important;
      }
    }
    span:hover {
      color: #18a8c7;
    }
  }
  .find,
  .setting-menu {
    z-index: 99;
    top: 62px;
    background: #eee;
    min-width: 120px;
    left: -42px;
    line-height: 40px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    .find-item,
    .setting-item {
      width: 150px;
      height: 40px;
      &:hover {
        background-color: #18a8c7;
        span {
          color: #fff !important;
        }
        .a-text {
          color: #fff !important;
        }
      }
      .notice {
        display: inline-block;
        .message-icon {
          top: 20%;
          right: -40%;
          color: #f00;
        }
      }
    }
  }
  div.user-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    div.user-head {
      padding-right: 8px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    div.user-name {
      display: flex;
      align-items: center;

      span {
        max-width: 7em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        font-family: 'PingFangSC-Regular', 'PingFang SC';
        font-weight: 400;
        color: #fff;
      }
      i {
        padding-left: 7px;
        color: #fff;
        font-weight: bold;
      }
    }
    .user-name:hover {
      span,
      i {
        color: #18a8c7 !important;
      }
    }
  }
  .nav-box {
    display: inline-block;
  }
}
</style>

<style lang="less">
// .material-nav-popover.ant-popover{
//   z-index: 2006;
//   .ant-popover-content{
//     .ant-popover-inner-content{
//       padding: 0;
//     }
//     .material-nav{
//       width: 90px;
//       height: 102px;
//       .extra-nav{
//         height: 34px;
//         display: block;
//         width: 100%;
//         line-height: 34px;
//         color: rgba(0, 0, 0, 0.65);
//         font-family: PingFangSC-Regular, PingFang SC;
//         padding-left: 12px;
//         box-sizing: border-box;
//         cursor: pointer;
//         &:hover{
//           background: rgba(0, 0, 0, 0.06);
//         }
//       }
//       .active-extra{
//         background: rgba(0, 0, 0, 0.06);
//       }
//     }
//   }
// }

.lang-popover {
  .lang-link-btn {
    color: #fff !important;
    text-align: left !important;
    border-radius: 0;
  }
  .lang-link-btn:hover {
    background-color: #1c98bb !important;
  }
  .ant-popover-title,
  .ant-popover-inner-content {
    padding: 0 !important;
    display: flex;
    min-width: 150px;
    width: 150px;
  }
  .ant-popover-inner-content {
    padding-left: 10px !important;
  }
  .ant-popover-inner,
  .ant-popover-arrow {
    background-color: rgb(33, 33, 33);
    border-top-color: rgba(0, 0, 0, 0.06) !important;
    border-left-color: rgba(0, 0, 0, 0.06) !important;
  }
}
</style>
