import { getThirdData } from '@/apis/common/thirdData'
import GLayout from '@/components/common/g-layout'
import bluenHome from '@/views/bluenHome'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'

import store from '../store'
import commonFun from '../util/commonFun'

Vue.use(Router)

// 设备对接相关路由
const equipmentRoutes = [
  {
    path: '/equipment',
    name: 'equipment',
    meta: {
      nav: 'equip',
    },
    // component: equipment,
    component: () => import('@/views/equipment/equipment.vue'),
  },
  {
    path: '/bluenPtp',
    name: 'bluenPtp',
    meta: {
      nav: 'equip',
    },
    // component: bluenPtp,
    component: () => import('@/views/equipment/bluenPtp'),
  },
  {
    path: '/engraving',
    name: 'engraving',
    meta: {
      nav: 'equip',
    },
    // component: engraving,
    component: () => import('@/views/equipment/engraving'),
  },
  {
    path: '/electronicSaw',
    name: 'electronicSaw',
    meta: {
      nav: 'equip',
    },
    // component: electronicSaw,
    component: () => import('@/views/equipment/electronicSaw'),
  },
  {
    path: '/fiveFace',
    name: 'fiveFace',
    meta: {
      nav: 'equip',
    },
    // component: fiveFace,
    component: () => import('@/views/equipment/fiveFace'),
  },
  {
    path: '/sixFace',
    name: 'sixFace',
    meta: {
      nav: 'equip',
    },
    // component: sixFace,
    component: () => import('@/views/equipment/sixFace'),
  },
  {
    path: '/dockFiveSix',
    name: 'dockFiveSix',
    meta: {
      nav: 'equip',
    },
    // component: dockFiveSix,
    component: () => import('@/views/equipment/dockFiveSix.vue'),
  },
  {
    path: '/equipmentSetting',
    name: 'equipmentSetting',
    meta: {
      nav: 'equip',
    },
    // component: equipmentSetting,
    component: () =>
      import('@/views/equipment/equip-setting/equip-setting.vue'),
  },
  {
    path: '/sideHole',
    name: 'sideHole',
    meta: {
      nav: 'equip',
    },
    // component: sideHole,
    component: () => import('@/views/equipment/sideHole.vue'),
  },
]

// 修改路由配置的name属性，使得成为唯一
const generateRoutesName = (routes, nameSuffix = '') => {
  const copyRoutes = cloneDeep(routes)
  if (!copyRoutes) return
  copyRoutes.forEach((route) => {
    if (route.name) {
      if (nameSuffix) {
        route.name = route.name + nameSuffix
      } else {
        route.name = route.name + Math.random().toString(36).substr(2)
      }
    }
    if (route.path) {
      if (nameSuffix) {
        route.path = route.path + nameSuffix
      }
    }
    if (route.children) {
      generateRoutesName(route.children)
    }
  })
  return copyRoutes
}

var router = new Router({
  routes: [
    {
      path: '/bluenHome',
      name: 'bluenHome',
      component: bluenHome,
      children: [
        {
          path: '/equipment',
          name: 'equipment',
          meta: {
            nav: 'equip',
          },
          // component: equipment,
          component: () => import('@/views/equipment/equipment.vue'),
        },
        {
          path: '/equipmentSetting',
          name: 'equipmentSetting',
          meta: {
            nav: 'equip',
          },
          // component: equipmentSetting,
          component: () =>
            import('@/views/equipment/equip-setting/equip-setting.vue'),
        },
        {
          path: '/paibanHistory',
          name: 'paibanHistory',
          meta: {
            nav: 'history',
          },
          // component: paibanHistory,
          component: () => import('@/views/paibanHistory/paibanHistory.vue'),
        },
        // 设备联机
        {
          path: '/workShopProduction',
          name: 'workShopProduction',
          redirect: '/workShopProduction/fileManage',
          meta: {
            nav: 'workShop',
            breadcrumb: {
              name: '设备联机',
            },
          },
          component: GLayout,
          children: [
            {
              path: '/workShopProduction/fileManage',
              name: 'workShopProduction_fileManage',
              meta: {
                nav: 'workShop',
                breadcrumb: {
                  name: '生产文件管理',
                  parent: 'workShopProduction',
                },
              },
              component: () => import('@/views/workShopProduction/index.vue'),
            },
            {
              path: '/workShopProduction/bujian',
              name: 'workShopProduction_bujian',
              meta: {
                nav: 'workShop',
                // 保留上级的路由name
                breadcrumb: {
                  name: '补件',
                  parent: 'workShopProduction_fileManage',
                },
              },
              component: () =>
                import('@/views/workShopProduction/components/m-bujian.vue'),
            },
          ],
        },
        {
          path: '/bluenPtp',
          name: 'bluenPtp',
          meta: {
            nav: 'equip',
          },
          // component: bluenPtp,
          component: () => import('@/views/equipment/bluenPtp'),
        },
        {
          path: '/engraving',
          name: 'engraving',
          meta: {
            nav: 'equip',
          },
          // component: engraving,
          component: () => import('@/views/equipment/engraving'),
        },
        {
          path: '/electronicSaw',
          name: 'electronicSaw',
          meta: {
            nav: 'equip',
          },
          // component: electronicSaw,
          component: () => import('@/views/equipment/electronicSaw'),
        },
        {
          path: '/fiveFace',
          name: 'fiveFace',
          meta: {
            nav: 'equip',
          },
          // component: fiveFace,
          component: () => import('@/views/equipment/fiveFace'),
        },
        {
          path: '/sixFace',
          name: 'sixFace',
          meta: {
            nav: 'equip',
          },
          // component: sixFace,
          component: () => import('@/views/equipment/sixFace'),
        },
        {
          path: '/dockFiveSix',
          name: 'dockFiveSix',
          meta: {
            nav: 'equip',
          },
          // component: dockFiveSix,
          component: () => import('@/views/equipment/dockFiveSix.vue'),
        },
        {
          path: '/materialList',
          name: 'materialList',
          meta: {
            nav: 'material',
            child: 'material',
          },
          // component: newMaterialList,
          component: () =>
            import('@/views/materialsList/newMaterialListV2.vue'),
        },
        {
          path: '/taskList',
          name: 'taskList',
          component: () => import('@/views/taskList/index.vue'),
        },
        {
          path: '/baseMaterial',
          name: 'baseMaterial',
          meta: {
            nav: 'baseMaterial',
          },
          // component: baseMaterial,
          component: () => import('@/views/materialsList/baseMaterial'),
        },
        {
          path: '/bujianManage',
          name: 'bujianManage',
          meta: {
            nav: 'bujianManage',
          },
          // component: baseMaterial,
          component: () => import('@/views/bujianManage'),
        },
        // {
        //   path: '/drawParts',
        //   name: 'drawParts',
        //   meta: {
        //     nav: 'draw',
        //   },
        //   // component: drawParts,
        //   component: () => import('@/views/drawParts/drawAllParts.vue'),
        // },
        {
          path: '/newPaiban',
          name: 'newPaiban',
          meta: {
            nav: 'draw',
          },
          // component: newPaiban,
          component: () => import('@/views/newPaiban/drawAllParts.vue'),
        },
        {
          path: '/printTag',
          name: 'printTag',
          meta: {
            nav: 'printTag',
          },
          // component: printTag,
          component: () => import('@/views/printTag/printTag2.vue'),
        },
        {
          path: '/printTagNew',
          name: 'printTagNew',
          meta: {
            nav: 'draw',
          },
          component: () => import('@/views/printTag/printTagNew.vue'),
        },
        {
          path: '/fileManage',
          name: 'fileManage',
          meta: {
            nav: 'fileManage',
          },
          component: () => import('@/views/fileManage/fileManage.vue'),
        },
        // 更多设置
        {
          path: '/moreSettings',
          name: 'moreSettings',
          meta: {
            nav: 'moreSettings',
          },
          component: () => import('@/views/moreSettings/moreSettings.vue'),
        },
        {
          path: '/editTagTem',
          name: 'editTagTem',
          meta: {
            nav: 'printTag',
          },
          // component: editTagTem,
          component: () => import('@/views/printTag/editTagTem.vue'),
        },
        {
          path: '/print',
          name: 'print',
          meta: {
            nav: '',
            hideHead: true,
          },
          component: () => import('@/views/printTag/openPrintTag2.vue'),
          // component: () => import('../views/printTag/editTagTem.vue')
        },
        {
          path: '/sideHole',
          name: 'sideHole',
          meta: {
            nav: 'equip',
          },
          // component: sideHole,
          component: () => import('@/views/equipment/sideHole.vue'),
        },
        // {
        //   path: '/subtle',
        //   name: 'subtle',
        //   meta: {
        //     nav: 'draw',
        //   },
        //   // component: subtle,
        //   component: () => import('@/views/drawParts/subtle.vue'),
        // },
        {
          path: '/login',
          name: 'login',
          // component: login,
          component: () => import('@/views/login.vue'),
          meta: {
            noToken: true,
            hideHead: true,
          },
        },
        {
          path: '/register',
          name: 'register',
          // component: register,
          component: () => import('@/views/register.vue'),
          meta: {
            noToken: true,
            hideHead: true,
          },
        },
        {
          path: '/editPwd',
          name: 'editPwd',
          // component: editPwd,
          component: () => import('@/views/editPwd.vue'),
          meta: {
            noToken: true,
            hideHead: true,
          },
        },
        {
          path: '/customFile',
          name: 'customFile',
          meta: {
            nav: 'equip',
          },
          // component: customFile,
          component: () => import('@/views/equipment/customFile.vue'),
        },
        {
          path: '/supplusStorage',
          name: 'supplusStorage',
          meta: {
            nav: 'surplus',
            child: 'supplus',
          },
          // component: supplusStorage,
          component: () => import('@/views/materialsList/supplusStorage.vue'),
        },
        {
          path: '/equipEnvironment',
          name: 'equipEnvironment',
          meta: {
            hideHead: true,
          },
          // component: equipEnvironment,
          component: () =>
            import('@/views/equipEnvironment/equipEnvironment.vue'),
        },
        {
          path: '/preLayoutSetting',
          name: 'preLayoutSetting',
          meta: {
            nav: 'preLayoutSetting',
          },
          component: () => import('@/views/preLayoutSetting/index.vue'),
        },
        // {
        //   path: '/paibanView',
        //   name: 'paibanView',
        //   meta: {
        //     hideHead: true,
        //     noToken: true,
        //   },
        //   component: paibanView,
        // },
      ],
    },
    {
      path: '/',
      name: 'home',
      // component: home,
      component: () => import('@/views/home.vue'),
      meta: {
        noToken: true,
      },
    },
    ...generateRoutesName(equipmentRoutes, '-preview'),
  ],
})
router.beforeEach(async (to, from, next) => {
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.path])
    }
  }
  if (to.path == '/preLayoutSetting') {
    const settingStorage = localStorage.getItem('newSettingRecord')
    const newSetting = settingStorage ? JSON.parse(settingStorage) : {}
    store.state.userInfo.id &&
      Object.assign(newSetting, {
        [store.state.userInfo.id]: { hasClickPreSetting: true },
      })
    localStorage.setItem('newSettingRecord', JSON.stringify(newSetting))
  }
  if (to.path == '/' || to.path == '/paibanHistory') {
    let urlObj = commonFun.getUrlVars()
    // 第三方平台跳转云排班
    if (
      urlObj.material_cache_data &&
      !sessionStorage.getItem('material_cache_data')
    ) {
      // 第三方数据参数处理
      sessionStorage.setItem('material_cache_data', urlObj.material_cache_data)
      if (urlObj.token) {
        sessionStorage.setItem('third_token', urlObj.token)
        const res = await getThirdData({
          token: urlObj.token,
          bluen_data_key: urlObj.material_cache_data,
        })
        const { refresh_token, token, plank_data } = res.data
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('refresh_token', refresh_token)
        store.commit('setThirdPlankData', plank_data)
        let path =
          '/materialList?material_cache_data=' + urlObj.material_cache_data
        next(path)
      } else {
        next('/login')
      }
    } else if (urlObj.thinkerx_material) {
      sessionStorage.setItem('thinkerx_material', urlObj.thinkerx_material)
      // 柜门跳转保存url参数
      urlObj.url && sessionStorage.setItem('thinkerx_url', urlObj.url)
      // 需要先从数据中截取一次工厂 id，后续还存在的工厂 id 的情况则替换掉
      const [factory_id] = urlObj.thinkerx_material.split('/')
      if (factory_id) {
        sessionStorage.setItem('thinkerx_material_factory_id', factory_id)
      }
      if (to.path != '/paibanHistory') {
        if (urlObj.guigui_token) {
          sessionStorage.setItem('token', urlObj.guigui_token)
          let path =
            '/materialList?thinkerx_material=' + urlObj.thinkerx_material
          next(path)
        } else {
          next('/login')
        }
      }
    }

    if (urlObj.factory_id) {
      sessionStorage.setItem('thinkerx_material_factory_id', urlObj.factory_id)
    }
  }
  if (to.path == '/equipment') {
    // 防止从后台进入过预览后，直接打开设备对接页面，造成影响
    const previewProductionLine =
      window.localStorage.getItem('previewProductLine') ||
      store.state?.equipEnvironment?.previewProductionLine
    if (
      window.sessionStorage.getItem('previewInfo') ||
      (previewProductionLine && Object.keys(previewProductionLine).length)
    ) {
      window.sessionStorage.removeItem('previewInfo')
      window.localStorage.removeItem('previewProductLine')
      store.commit('equipEnvironment/setPreviewProductLine', {})
    }

    let urlObj = commonFun.getUrlVars()
    if (urlObj?.guigui_equipment === '1') {
      window.sessionStorage.setItem(
        'guiguiJumpEquipmentParams',
        JSON.stringify(urlObj)
      )
    }
  }
  // 来自于后台的预览
  if (to.path.includes('/equipment-preview')) {
    const {
      isPreview,
      previewToken,
      jsonUrl,
      settingId,
      isApply,
      operatorPhone,
    } = to.query
    const previewInfo = store.state.equipEnvironment?.previewInfo

    if (!Object.keys(previewInfo).length) {
      store.commit('equipEnvironment/setPreviewInfo', {
        isPreview: isPreview === 'true' ? true : false,
        previewJsonUrl: jsonUrl,
        settingId,
        isApply: isApply === 'true' ? true : false,
        operatorPhone,
      })
      window.sessionStorage.setItem('token', previewToken)
      window.sessionStorage.setItem(
        'previewInfo',
        JSON.stringify({
          ...to.query,
          isPreview: isPreview === 'true' ? true : false,
          isApply: isApply === 'true' ? true : false,
          previewJsonUrl: jsonUrl,
          operatorPhone,
        })
      )
    }
  }

  // 防止从后台进入过预览后，直接打开其他页面，造成影响
  if (!to.path.includes('-preview') && from.path.includes('-preview')) {
    // 移除token
    window.sessionStorage.removeItem('token')
    window.sessionStorage.removeItem('previewInfo')
    window.localStorage.removeItem('previewProductLine')
  }

  if (!to.meta.noToken && !store.state.userInfo.id) {
    Vue.prototype.$token('/get_user_info', '', (res) => {
      if (res.status == 1) {
        store.commit('setUserInfo', res.data)
        localStorage.setItem('testNowTagId', res.data.custom_tag_id)
        next()
      } else {
        next('/login')
      }
    })
  } else {
    next()
  }
})
export default router
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
