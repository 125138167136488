import { nanoid } from 'nanoid'

import store from '../store'
import libCurve from './Curve'
import { uniquePlankNum } from './commonFuncs'

export function dealThinkerxMaterialData(special_data) {
  let arr = []
  let extraInfoKeys = []
  for (let i = 0; i < special_data.length; i++) {
    if (i == 0) {
      for (let key in special_data[i].remark_names) {
        extraInfoKeys.push({
          label: special_data[i].remark_names[key],
          value: key,
          text: special_data[i].remark_names[key],
        })
      }
      store.commit('setThinkerxMaterialKeys', extraInfoKeys)
    }
    let plank = special_data[i]
    if (plank.shape && plank.shape.length) {
      const curve = libCurve.newCurve()
      curve.points = plank.shape
      plank.shape = curve.getPolygon(true) // 处理圆弧数据
      // 异形的height 和 width
      // 根据传过来的path数据找出最大的和最小的x,y
      const { minX, maxX, minY, maxY } = plank.shape.reduce(
        ({ minX, minY, maxX, maxY }, { x, y }) => ({
          minX: Math.min(minX, x),
          minY: Math.min(minY, y),
          maxX: Math.max(maxX, x),
          maxY: Math.max(maxY, y),
        }),
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
      )
      plank.width = maxX - minX
      plank.height = maxY - minY
    }
    // 临时处理 柜门板件纹理为横纹时，将板件的宽高对调
    if (plank.grain == 1) {
      ;[plank.width, plank.height] = [plank.height, plank.width]
    }
    plank.customer_name = plank.customer_name
    plank.customer = plank.customer
    plank.edgeInfo = `←${plank.leftEdge}↓${plank.frontEdge}→${plank.rightEdge}↑${plank.backEdge}`
    // plankNum
    if (plank.code) {
      plank.plankNum = plank.code
      plank.oriPlankNum = plank.code
    } else {
      const plankNum = uniquePlankNum.createUniquePlankNum()
      plank.plankNum = plankNum
      plank.oriPlankNum = plankNum
    }
    plank.edgeInfo = `←${plank.leftEdge ?? 0}↓${plank.frontEdge ?? 0}→${
      plank.rightEdge ?? 0
    }↑${plank.backEdge ?? 0}`
    // 板件成品尺寸
    plank.fullSize = {
      width: plank.width,
      height: plank.height,
    }
    // 板件切割长宽
    plank.rect = {
      x: 0,
      y: 0,
      width: Number(plank.width - plank.leftEdge - plank.rightEdge),
      height: Number(plank.height - plank.frontEdge - plank.backEdge),
    }
    // 板件成品长宽
    plank.oRect = {
      x: 0,
      y: 0,
      width: Number(plank.width),
      height: Number(plank.height),
    }
    ;(plank.realRect = {
      x: 0,
      y: 0,
      width: plank.rect.width,
      height: plank.rect.height,
    }),
      (plank.realCurve = [
        { x: 0, y: 0 },
        { x: plank.oRect.width, y: 0 },
        { x: plank.oRect.width, y: plank.oRect.height },
        { x: 0, y: plank.oRect.height },
      ])
    plank.realCurveEx = []
    plank.sholes = []
    plank.sslots = []
    // 处理板件数量
    plank.amount = Number(Number(plank.quantity).toFixed(0))
    // 处理板件id,  暂无
    plank.plankID = i + 1
    // 处理板件名称
    plank.partName = plank.wardrobeName
      ? plank.wardrobeName + '_' + plank.name
      : plank.name
    // 处理孔槽信息
    if (plank.holes && plank.holes.length > 0) {
      plank.holes = plank.holes.filter((v) => v.face == 0 || v.face == 5)
      for (let k = 0; k < plank.holes.length; k++) {
        let hole = plank.holes[k]
        let y = plank.oRect.height - hole.y
        hole.ocenter = {
          x: hole.x,
          y: y,
        }
        hole.center = {
          x: hole.x - plank.leftEdge,
          y: y - plank.frontEdge,
        }
        hole.deep = hole.depth
        hole.side = hole.face == 0 ? 1 : -1
        // plank.holes[i].
        hole.holeType = 'hole'
        hole.uniqueId = nanoid()
      }
    }
    // 处理拉槽数据
    if (plank.slots && plank.slots.length > 0) {
      plank.slots.filter((v) => v.face == 0 || v.face == 5)
      for (let k = 0; k < plank.slots.length; k++) {
        let slot = plank.slots[k]
        const w = Math.abs(slot.x1 - slot.x2)
        const h = Math.abs(slot.y1 - slot.y2)
        slot.d = Math.min(w, h)
        slot.width = slot.d
        const [minX, maxX] = [slot.x1, slot.x2].sort((a, b) => a - b)
        const [minY, maxY] = [slot.y1, slot.y2].sort((a, b) => a - b)
        // 竖拉槽
        if (w < h) {
          slot.opt1 = {
            x: minX + slot.d / 2,
            y: minY,
          }
          slot.opt2 = {
            x: maxX - slot.d / 2,
            y: maxY,
          }
          slot.pt1 = {
            x: minX + slot.d / 2 - (plank.leftEdge || 0),
            y: minY - (plank.frontEdge || 0),
          }
          slot.pt2 = {
            x: maxX - slot.d / 2 - (plank.leftEdge || 0),
            y: maxY - (plank.frontEdge || 0),
          }
        } else {
          // 横拉槽
          slot.opt1 = {
            x: minX,
            y: minY + slot.d / 2,
          }
          slot.opt2 = {
            x: maxX,
            y: maxY - slot.d / 2,
          }
          slot.pt1 = {
            x: minX - (plank.leftEdge || 0),
            y: minY + slot.d / 2 - (plank.frontEdge || 0),
          }
          slot.pt2 = {
            x: maxX - (plank.leftEdge || 0),
            y: maxY - slot.d / 2 - (plank.frontEdge || 0),
          }
        }

        // 柜门跳转拉槽位置需要进行上下颠倒
        slot.pt1.y = plank.rect.height - slot.pt1.y
        slot.pt2.y = plank.rect.height - slot.pt2.y
        slot.opt1.y = plank.oRect.height - slot.opt1.y
        slot.opt2.y = plank.oRect.height - slot.opt2.y
        slot.deep = slot.depth
        slot.side = slot.face == 0 ? 1 : -1
        slot.knifeName = slot.knifeName
        if (!slot.knifeName) delete slot['knifeName']
        slot.uniqueId = nanoid()
      }
    }
    // 统一旋转为false
    plank.matRotatable = false
    switch (plank.grain) {
      case 0:
        plank.texDir = 'normal'
        break
      case 1:
        plank.texDir = 'reverse'
        break
      case 2:
        plank.texDir = 'notcare'
        break
      default:
        plank.texDir = 'normal'
    }
    // 记录板件的成品宽度
    plank.specWidth = plank.width
    // 记录板件的成品长度
    plank.specHeight = plank.height
    // 处理板件孔槽信息
    plank.hsInfo = `${plank.holes.length}孔${plank.slots.length}槽`
    // 处理长圆孔数据(目前只有牛角槽)
    if (plank.curveHoles) {
      plank.curveHoles.forEach((hole) => {
        hole.path = hole.path.map((point) => ({
          x: point.x,
          y: plank.rect.height - point.y,
        }))
        hole.side = hole.face == '0' ? 1 : -1
      })
    }
    // 处理板件是否有异形
    plank.specialShape = plank.shape
      ? plank.shape.length == 0
        ? false
        : true
      : false
    // 存在长圆孔且不存在异形，生成异形路径
    if (plank?.curveHoles?.length && !plank.specialShape) {
      plank.oPath = [
        [
          { x: 0, y: 0 },
          { x: plank.oRect.width, y: 0 },
          { x: plank.oRect.width, y: plank.oRect.height },
          { x: 0, y: plank.oRect.height },
        ],
      ]
      plank.path = [
        [
          { x: 0, y: 0 },
          { x: plank.rect.width, y: 0 },
          { x: plank.rect.width, y: plank.rect.height },
          { x: 0, y: plank.rect.height },
        ],
      ]
    }
    if (plank.specialShape) {
      let newShape = plank.shape.map((v) => {
        let y = plank.rect.height - v.y
        return {
          x: v.x,
          y,
        }
      })
      const { minX, minY } = newShape.reduce(
        ({ minX, minY }, { x, y }) => ({
          minX: Math.min(minX, x),
          minY: Math.min(minY, y),
        }),
        { minX: Infinity, minY: Infinity }
      )
      for (let point of newShape) {
        point.x = point.x - minX
        point.y = point.y - minY
      }
      plank.path = [newShape]
      plank.oPath = [newShape]
      // TODO 老板良封边扩张处理待定
      // plank.oPath = [
      //   newShape.map((v) => {
      //     let x = (v.x * plank.rect.width) / plank.oRect.width
      //     let y = (v.y * plank.rect.height) / plank.oRect.height
      //     return {
      //       x,
      //       y,
      //     }
      //   }),
      // ]
    }
    plank.thick = Number(plank.thick)
    plank.type = 'SingleDoor'
    // 处理封边信息
    // plank.frontEdge = plank.frontEdge
    // plank.backEdge = plank.backEdge
    // plank.leftEdge = plank.leftEdge
    // plank.rightEdge = plank.rightEdge
    arr.push(plank)
  }
  uniquePlankNum.plankNumCollect.clear()
  return arr
}
